import React from 'react';
import Homepage from './components/pages/Homepage';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Detail from "./components/pages/posts/Detail";
import Aktuality from './components/pages/Aktuality';
import NotFound from './components/pages/NotFound';
import Section from './components/pages/posts/Section';
import TileFirst from './components/pages/TileFirst';
import TileSecond from './components/pages/TileSecond';
import TileThird from './components/pages/TileThird';
import TileFourth from './components/pages/TileFourth';
import Reference from './components/pages/Reference';
import SearchResults from './components/pages/SearchResults';
import InternalServerError from './components/pages/InternalServerError';
import { BrowserRouter, Switch, Route } from "react-router-dom";

export const routeCodes = {
  HOME: '/',  
  DETAILPOST: '/clanek/:slug',
  AKTUALITY: '/aktuality',
  CONTACT: '/kontakty',
  ABOUT: '/o-spolecnosti',
  SECTION: '/odpovedna-firma',
  TILE1: '/projekce-a-vyvoj', 
  TILE2: '/strojirenstvi',
  TILE3: '/technologie-pro-tezbu-a-energetiku',
  TILE4: '/fotovoltaika',
  SEARCHRESULTS: '/hledat/:search',
  REFERENCE: '/reference', 
  };

class App extends React.Component {
	render() {
		return (
      <BrowserRouter basename="">
        <Switch>
          <Route path={ routeCodes.HOME } component={Homepage} exact />          
          <Route path={ routeCodes.DETAILPOST } component={Detail} exact />
          <Route path={ routeCodes.AKTUALITY } component={Aktuality} exact />
          <Route path={ routeCodes.CONTACT } component={Contact} exact />
          <Route path={ routeCodes.ABOUT } component={About} exact />
          <Route path={ routeCodes.SECTION } component={Section} exact />
          <Route path={ routeCodes.TILE1 } component={TileFirst} exact />
          <Route path={ routeCodes.TILE2 } component={TileSecond} exact />
          <Route path={ routeCodes.TILE3 } component={TileThird} exact />
          <Route path={ routeCodes.TILE4 } component={TileFourth} exact />
          <Route path={ routeCodes.REFERENCE } component={Reference} exact />
          <Route path={ routeCodes.SEARCHRESULTS } component={SearchResults} exact />
          <Route path="/500" component={InternalServerError} />
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
		);
	}
}

export default App;
