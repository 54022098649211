import React from 'react';
import axios from 'axios';
import Navbar from '../../Navbar';
import Footer from '../../Footer';
import {
    WORDPRESS_URL,
    WP_CATEGORIES_SLUG,
    WP_POST_CATEGORIES_WITH_FEATUREDMEDIA
} from "../../../utils/constants";
import Preloader from "../../Preloader";
import renderHTML from "react-render-html";
import { Link, Redirect } from "react-router-dom";
import defaultBg from "../../../images/category-clanek.jpg";
import kosodelnik from "../../../images/kosodelnik.png";
import kosodelnikModry from "../../../images/kosodelnik-modry.png";

class Section extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: true,
            actualitiesArray: [],
            index: 1,
            headerBgClass: "home",
            firstRectSrc: kosodelnikModry,
            secondRectSrc: kosodelnik, 
            thirdRectSrc: kosodelnik, 
            nextHeaderBgIndex: 2,
            firstArticle: "",
            secondArticle: "hidden",
            thirdArticle: "hidden",
            error: {},
            redirectInternalError: false
        }

        this.bgToggle = this.bgToggle.bind(this);
    }

    bgToggle(number) {
        if (number === 1) {
          this.setState({
              firstRectSrc: kosodelnikModry,
              secondRectSrc: kosodelnik,
              thirdRectSrc: kosodelnik,
              headerBgClass: "projects",
              nextHeaderBgIndex: 2,
              firstArticle: "",
              secondArticle: "hidden",
              thirdArticle: "hidden"
          });
        } 
        else if (number === 2) {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnikModry,
                thirdRectSrc: kosodelnik,
                headerBgClass: "delivery",
                nextHeaderBgIndex: 3,
                firstArticle: "hidden",
                secondArticle: "",
                thirdArticle: "hidden"
            });
          } else {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnik,
                thirdRectSrc: kosodelnikModry,
                headerBgClass: "construction",
                nextHeaderBgIndex: 1,
                firstArticle: "hidden",
                secondArticle: "hidden",
                thirdArticle: ""
            });
        }
      }

    componentDidMount(){
        axios.get( WORDPRESS_URL + WP_CATEGORIES_SLUG + "odpovedna-firma")
        .then(response_slug => {
            if (response_slug !== undefined) {
                axios.get( WORDPRESS_URL + WP_POST_CATEGORIES_WITH_FEATUREDMEDIA + response_slug.data[0].id + '&per_page=100')
                .then(response_actualities => {
                    if (response_actualities !== undefined) {
                        const actualitiesArray = response_actualities.data.map(function (data, id){
                            let featuredMediaLinks = data._embedded['wp:featuredmedia'] ? data._embedded['wp:featuredmedia'] : null;
                            let featuredMediaLink = featuredMediaLinks ? `url("${featuredMediaLinks[0].source_url}")` : `url("${defaultBg}")`; //rest api na získání úvodního obrázku
                            return {
                                idx: id,
                                title: data.title.rendered,
                                text: data.content.rendered, 
                                shortText: data.excerpt.rendered,
                                slug: data.slug,
                                featuredMedia: featuredMediaLink
                            };
                        });
        
                        this.setState({
                            isLoading: false, 
                            actualitiesArray: actualitiesArray
                        }); 
                    }                    
                });
            }                                   
        })
        .catch( error => {
            console.log(error);  

            this.setState({ 
                error: error, 
                isLoading: false,
                redirectInternalError: true
            });       
        });

        this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }

    renderHeader = () => {
        return(
            <header className={"category-header " + this.state.headerBgClass}>
                <Navbar />
                
                <div className="sd-container">
                    <section id="sub-header">
                    <div className="row">
                        <div className="col-md-7 col-xl-8 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="sub-header-sub-caption text-uppercase prodeco-h6">
                            PRODECO
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="sub-header-caption text-uppercase">
                            Odpovědná
                            <br />
                            firma
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                        <span className="sub-header-chevrons">
                        <img
                            src={this.state.firstRectSrc}
                            alt="chevron_white"
                            className="first header-toogle-button"
                            onClick={() => this.bgToggle(1)}
                        />
                        <img
                            src={this.state.secondRectSrc}
                            alt="chevron_sd"
                            className="second header-toogle-button"
                            onClick={() => this.bgToggle(2)}
                        />
                        
                        <img
                            src={this.state.thirdRectSrc}
                            alt="chevron_sd"
                            className="third header-toogle-button"
                            onClick={() => this.bgToggle(3)}
                        />
                        </span>
                    </div>
                    </div>
                    <div className={"col-md-5 col-xl-4 " + this.state.firstArticle}>
                    <div className="d-flex justify-content-between align-items-center sd-mt-4">
                        <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                        <u><b style={{fontSize:"1rem"}}>PRO</b></u>jects
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="sub-header-text">
                        Společnost disponuje unikátním know-how podpořeným týmem autorizovaných inženýrů,
                        kvalifikovaných projektantů a konstruktérů s dlouholetými zkušenostmi 
                        především v oborech strojní, elektro, stavby a ocelové konstrukce 
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    {/* <Link
                        to={"/projects"}
                        className="who-we-are-more-btn-link"
                        > */}
                        <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                        <span className="btn-text text-white text-uppercase pl-4">
                            Zjistit více{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                        </div>
                        {/* </Link> */}
                    </div>
                    </div>

                    <div className={"col-md-5 col-xl-4 " + this.state.secondArticle}>
                    <div className="d-flex justify-content-between align-items-center sd-mt-4">
                        <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                        <u><b style={{fontSize:"1rem"}}>DE</b></u>livery
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="sub-header-text">
                        Díky projekčním, výrobním a montážním kapacitám dodáváme velké projekty na klíč
                        a to včetně souvisejících dodávek a služeb. Samozřejmostí je poskytování následného servisu
                        jako jsou dodávky náhradních dílů a provádění oprav a údržby nejen dodaných zařízení 
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    {/* <Link
                        to={"/construction"}
                        className="who-we-are-more-btn-link"
                        > */}
                        <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                        <span className="btn-text text-white text-uppercase pl-4">
                            Zjistit více{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                        </div>
                        {/* </Link> */}
                    </div> 
                    </div>

                    <div className={"col-md-5 col-xl-4 " + this.state.thirdArticle}>
                    <div className="d-flex justify-content-between align-items-center sd-mt-4">
                        <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                        <u><b style={{fontSize:"1rem"}}>CO</b></u>nstruction
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="sub-header-text">
                        Evropská špička v oblasti těžkého strojírenství <br/>
                        75 let tradice
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    {/* <Link
                        to={"/delivery"}
                        className="who-we-are-more-btn-link"
                        > */}
                        <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                        <span className="btn-text text-white text-uppercase pl-4">
                            Zjistit více{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                        </div>
                        {/* </Link> */}
                    </div> 
                    </div>
                </div>
                </section>
            </div>
        </header>
    );
}

    renderMainContainer = () => {
        return(
            <section id="main-container">
                <div className="sd-container-aktuality category">
                    <div className="d-flex sd-container-aktuality-flex sd-container-category">
                        <div>
                        {this.renderArticles()} 
                        </div>                       
                    </div>
                </div>
            </section>
        );
    }

    renderSecondaryContainer = () => {
        return(
            <section id="secondary-container" className="sd-hidden-xs contact-secondary-container"></section>
        );
    }

    renderArticles = () => {
        const { actualitiesArray } = this.state;

        const actualities = actualitiesArray.map((actuality, index) =>
            <Link to={`clanek/${actuality.slug}`}>
                <div className="sd-category-article" key={index} style={{backgroundImage: actuality.featuredMedia }}>
                    <div className="sd-category-article-body">
                        <div className="sd-article-title text-uppercase">{ renderHTML(actuality.title) }</div>
                        <div className="sd-article-content">{ renderHTML(actuality.shortText) }</div>
                        
                        <div className="sd-article-link"> 
                            <span className="pull-right text-uppercase">
                                <b>Přečíst</b> článek
                                <i className="fa fa-chevron-right sd-text-primary ml-2"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </Link>
        );

        return actualities;
    }

    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>           
        );
    }

    render(){
        const { isLoading, redirectInternalError, error } = this.state;

        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                ) : (redirectInternalError ? (
                    <Redirect to={{
                        pathname: "/500",
                        state: { error: error }
                      }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}

                        {this.renderMainContainer()}

                        {this.renderSecondaryContainer()}

                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default Section;