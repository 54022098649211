import axios from 'axios';
import qs from 'querystring';
import { BASE_API_URL, TOKEN_URL, SDAS_API_URL } from './utils/constants';
import setAuthorizationToken from './utils/setAuthorizationToken';
import functions from './utils/functions';

const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Accept, Authorization, X-Request-With"
  };

  const headers2 = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Accept, X-Request-With"
  };

export default {
    contact: {
      send: (emailObject) => {
        return axios.post(
          SDAS_API_URL + "/utils/emails/send/info", 
          qs.stringify(emailObject),
          {
            headers: { 
            "Content-Type": "application/x-www-form-urlencoded"
          }}
        ).then(res => {
          return res;
        }).catch(err => {
          console.log(err);
        });
      }
    }
}


  