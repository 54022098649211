import React from 'react';
import { Link } from 'react-router-dom';
import logoFooter from '../images/logo_footer.png';

class Footer extends React.Component{

    constructor(props){
        super(props);

        this.state = {
        }
    }

    render(){
        return (
            <div>
        <div className="row">
            <div className="col-md-12 d-flex footer-logo align-items-center">
                <a href="/clanek/skupina-sd">
                    <img src={logoFooter} alt="logo_footer"/>
                </a>
            </div>
        </div>

        <hr className="hr-white" />

        <div className="row footer-text">
            <div className="col-md-6">
                <div className="row mt-3 mb-2">
                    <div className="col-sm sd-hidden-xs"><a href="/kontakty" className="text-white">Kontaktujte nás</a></div>
                    <div className="col-sm sd-visible-xs font-weight-bold">Kontaktujte nás</div>
                </div>

                <div className="row">
                    <div className="col-md-4 sd-hidden-xs">Email</div>
                    <div className="col-md-8">prodeco@prodeco.cz</div>
                </div>

                <div className="row">
                    <div className="col-md-4 sd-hidden-xs">Adresa</div>
                    <div className="col-md-8">Důlní 437<br />Bílina, 418 01</div>
                </div>
            </div>
            <div className="col-md-6">
                {/* <div className="row mt-3 mb-3">
                    <div className="col-sm sd-hidden-xs"><a href="/odpovedna-firma" className="text-white">Odpovědná firma</a></div>
                    <div className="col-sm mt-3 sd-visible-xs font-weight-bold">Odpovědná firma</div>
                </div> */}

                <div className="row mt-5">
                    <div className="col-md-7">
                        <p className="m-0 "><a href="/o-spolecnosti" className="text-white">Společnost</a></p> 
                        <p className="m-0 "><a href="/clanek/ochrana-osobnich-udaju" className="text-white">Ochrana osobních údajů</a></p>
                        <p className="m-0"><a href="/clanek/eticka-linka-oznamovatele" className="text-white">Etická linka oznamovatele</a></p>
                    </div>

                    <div className="col-md-5">
                    <p className="m-0 "><a href="https://sdas.jobs.cz/o-spolecnosti/#prodeco" className="text-white">Práce u nás</a></p>
                    <p className="m-0 "><a href="/kontakty" className="text-white">Kontakty</a></p>
                    <p className="m-0 "><a href="/clanek/fakturace" className="text-white">Fakturace</a></p>

                    </div>
                </div>
            </div>
        </div>

        <div className="row mt-5 sd-hidden-xs">
            <div className="col-12 text-right">&copy; PRODECO, a.s</div>
        </div>

        <div className="actual-xs-footer">
            <hr className="hr-white-footer" />
        <div className="row mt-5">
            <div className="col-12 text-center">&copy; PRODECO, a.s</div>
        </div>
        </div>
        </div>
        )
    }
}

export default Footer;