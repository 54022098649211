import React, { useState } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import kosodelnik from "../../images/kosodelnik.png";
import kosodelnikModry from "../../images/kosodelnik-modry.png";
import home1 from "../../images/home1.jpg";
import home2 from "../../images/home2.jpg";
import home3 from "../../images/home3.jpg";
import {
  WORDPRESS_URL,
  WP_POSTS_SLUG,
  WP_POSTS_CATEGORIES,
  WP_CATEGORIES_SLUG
} from "../../utils/constants";
import Preloader from "../Preloader";

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        bodyPost: { idx: "", title: "", text: "", shortText: "", slug: "", date: "" },
        footerPost: { idx: "", title: "", text: "", shortText: "", slug: "", date: "" },
        footerCategory: { id: 0 },
        footerPosts: [],
        aktualityCategory: { id: 0 },
        actualityPosts: [],
        actualityPost: { idx: "", title: "", text: "", shortText: "", slug: "", date: "" },
        index: 1,
        indexRef: 1,
        headerBgClass: "home",
        firstRectSrc: kosodelnikModry,
        secondRectSrc: kosodelnik, 
        thirdRectSrc: kosodelnik, 
        nextHeaderBgIndex: 2,
        firstArticle: "",
        secondArticle: "hidden",
        thirdArticle: "hidden",
        whoWeAreImg: home1,
        whoWeAre: [home1, home2, home3],
        whoWeAreIndex: 1,
        error: "",
        isLoading: true
    };

    this.actualityToggle = this.actualityToggle.bind(this);
    this.referenceToggle = this.referenceToggle.bind(this);
    this.bgToggle = this.bgToggle.bind(this);
  }

  actualityToggle(direction) {
    if (direction === "next") {
      if (this.state.index === this.state.actualityPosts.length) {
        this.setState({
          index: 1,
          actualityPost: this.state.actualityPosts[0]
        });
      } else {
        let idx = this.state.index + 1;
        this.setState({
          index: idx,
          actualityPost: this.state.actualityPosts[idx - 1]
        });
      }
    } else {
      if (this.state.index === 1) {
        let idx = this.state.actualityPosts.length;
        this.setState({
          index: idx,
          actualityPost: this.state.actualityPosts[idx - 1]
        });
      } else {
        let idx = this.state.index - 1;
        this.setState({
          index: idx,
          actualityPost: this.state.actualityPosts[idx - 1]
        });
      }
    }
  }

  
  referenceToggle(direction) {
    if (direction === "next") {
      if (this.state.indexRef === this.state.footerPosts.length) {
        this.setState({
            indexRef: 1,
          footerPost: this.state.footerPosts[0]
        });
      } else {
        let idx = this.state.indexRef + 1;
        this.setState({
            indexRef: idx,
          footerPost: this.state.footerPosts[idx - 1]
        });
      }
    } else {
      if (this.state.indexRef === 1) {
        let idx = this.state.footerPosts.length;
        this.setState({
            indexRef: idx,
          footerPost: this.state.footerPosts[idx - 1]
        });
      } else {
        let idx = this.state.indexRef - 1;
        this.setState({
            indexRef: idx,
          footerPost: this.state.footerPosts[idx - 1]
        });
      }
    }
  }

  whoWeAreToggle(direction) {
    if (direction === "next") {
      if (this.state.whoWeAreIndex === this.state.whoWeAre.length) {
        this.setState({
            whoWeAreIndex: 1,
            whoWeAreImg: this.state.whoWeAre[0]
        });
      } else {
        let idx = this.state.whoWeAreIndex + 1;
        this.setState({
            whoWeAreIndex: idx,
            whoWeAreImg: this.state.whoWeAre[idx - 1]
        });
      }
    } else {
      if (this.state.whoWeAreIndex === 1) {
        let idx = this.state.whoWeAre.length;
        this.setState({
            whoWeAreIndex: idx,
            whoWeAreImg: this.state.whoWeAre[idx - 1]
        });
      } else {
        let idx = this.state.whoWeAreIndex - 1;
        this.setState({
            whoWeAreIndex: idx,
            whoWeAreImg: this.state.whoWeAre[idx - 1]
        });
      }
    }
  }

  bgToggle(number) {
    if (number === 1) {
      this.setState({
          firstRectSrc: kosodelnikModry,
          secondRectSrc: kosodelnik,
          thirdRectSrc: kosodelnik,
          headerBgClass: "projects",
          nextHeaderBgIndex: 2,
          firstArticle: "",
          secondArticle: "hidden",
          thirdArticle: "hidden"
      });
    } 
    else if (number === 2) {
        this.setState({
            firstRectSrc: kosodelnik,
            secondRectSrc: kosodelnikModry,
            thirdRectSrc: kosodelnik,
            headerBgClass: "delivery",
            nextHeaderBgIndex: 3,
            firstArticle: "hidden",
            secondArticle: "",
            thirdArticle: "hidden"
        });
      } else {
        this.setState({
            firstRectSrc: kosodelnik,
            secondRectSrc: kosodelnik,
            thirdRectSrc: kosodelnikModry,
            headerBgClass: "construction",
            nextHeaderBgIndex: 1,
            firstArticle: "hidden",
            secondArticle: "hidden",
            thirdArticle: ""
        });
    }
  }

  componentDidMount() {
    const WHO_WE_ARE = "kdo-jsme";
    const REFERENCE = "reference";
    const ACTUAL = "aktuality";

    axios
      .all([
        axios.get(WORDPRESS_URL + WP_POSTS_SLUG + WHO_WE_ARE),
        axios.get(WORDPRESS_URL + WP_CATEGORIES_SLUG  + REFERENCE),
        axios.get(WORDPRESS_URL + WP_CATEGORIES_SLUG + ACTUAL)
      ])
      .then(
        axios.spread((req1, req2, req3) => {
          this.setState({
            bodyPost: {
                idx: req1.data[0].id,
                title: req1.data[0].title.rendered,
                text: req1.data[0].content.rendered, 
                shortText: req1.data[0].excerpt.rendered,
                slug: req1.data[0].slug,
                date: req1.data[0].date
            },
            aktualityCategory: req3.data[0],
            footerCategory: req2.data[0]
          });

          axios
          .get(WORDPRESS_URL + WP_POSTS_CATEGORIES + req2.data[0].id)
          .then(res2 => {
            const footerPosts = res2.data
              .map(function(footer, idx) {
                  return {
                      idx: footer.id,
                      title: footer.title.rendered,
                      text: footer.content.rendered, 
                      shortText: footer.excerpt.rendered,
                      slug: footer.slug,
                      date: footer.date
                  };
              });

            this.setState({
                footerPosts: footerPosts,
                footerPost: footerPosts[0] ? footerPosts[0] : { idx: "", title: "", text: "", shortText: "", slug: "", date: "" },
              isLoading: false
            });
          });

          axios
            .get(WORDPRESS_URL + WP_POSTS_CATEGORIES + req3.data[0].id)
            .then(res3 => {
              const actualityPosts = res3.data
                .slice(0, 4)
                .map(function(actuality, idx) {
                    return {
                        idx: actuality.id,
                        title: actuality.title.rendered,
                        text: actuality.content.rendered, 
                        shortText: actuality.excerpt.rendered,
                        slug: actuality.slug,
                        date: actuality.date
                    };
                });

              this.setState({
                actualityPosts: actualityPosts,
                actualityPost: actualityPosts[0] ? actualityPosts[0] : { idx: "", title: "", text: "", shortText: "", slug: "", date: "" },
                isLoading: false
              });
            });
        })
      );

      this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

renderHeader = () => {
    return(
        <header className={this.state.headerBgClass}>
            <Navbar />
            
            <div className="sd-container">
                <section id="sub-header">
                <div className="row">
                    <div className="col-md-7 col-xl-8 ">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="sub-header-sub-caption text-uppercase prodeco-h6">
                        PRODECO
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="sub-header-caption text-uppercase">
                        Huge things 
                        <br />
                        for the <br /> huge world
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="sub-header-chevrons">
                        <img
                            src={this.state.firstRectSrc}
                            alt="chevron_white"
                            className="first header-toogle-button"
                            onClick={() => this.bgToggle(1)}
                        />
                        <img
                            src={this.state.secondRectSrc}
                            alt="chevron_sd"
                            className="second header-toogle-button"
                            onClick={() => this.bgToggle(2)}
                        />
                        
                        <img
                            src={this.state.thirdRectSrc}
                            alt="chevron_sd"
                            className="third header-toogle-button"
                            onClick={() => this.bgToggle(3)}
                        />
                        </span>
                    </div>
                    </div>
                    <div className={"col-md-5 col-xl-4 " + this.state.firstArticle}>
                    <div className="d-flex justify-content-between align-items-center sd-mt-4">
                        <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                        <u><b style={{fontSize:"1rem"}}>PRO</b></u>jects
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="sub-header-text">
                        Společnost disponuje unikátním know-how podpořeným týmem autorizovaných inženýrů,
                        kvalifikovaných projektantů a konstruktérů s dlouholetými zkušenostmi 
                        především v oborech strojní, elektro, stavby a ocelové konstrukce 
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    {/* <Link
                        to={"/projects"}
                        className="who-we-are-more-btn-link"
                        > */}
                        <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                        <span className="btn-text text-white text-uppercase pl-4">
                            Zjistit více{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                        </div>
                        {/* </Link> */}
                    </div>
                    </div>

                    <div className={"col-md-5 col-xl-4 " + this.state.secondArticle}>
                    <div className="d-flex justify-content-between align-items-center sd-mt-4">
                        <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                        <u><b style={{fontSize:"1rem"}}>DE</b></u>livery
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="sub-header-text">
                        Díky projekčním, výrobním a montážním kapacitám dodáváme velké projekty na klíč
                        a to včetně souvisejících dodávek a služeb. Samozřejmostí je poskytování následného servisu
                        jako jsou dodávky náhradních dílů a provádění oprav a údržby nejen dodaných zařízení 
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    {/* <Link
                        to={"/construction"}
                        className="who-we-are-more-btn-link"
                        > */}
                        <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                        <span className="btn-text text-white text-uppercase pl-4">
                            Zjistit více{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                        </div>
                        {/* </Link> */}
                    </div> 
                    </div>

                    <div className={"col-md-5 col-xl-4 " + this.state.thirdArticle}>
                    <div className="d-flex justify-content-between align-items-center sd-mt-4">
                        <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                        <u><b style={{fontSize:"1rem"}}>CO</b></u>nstruction
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="sub-header-text">
                        Evropská špička v oblasti těžkého strojírenství <br/>
                        75 let tradice
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    {/* <Link
                        to={"/delivery"}
                        className="who-we-are-more-btn-link"
                        > */}
                        <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                        <span className="btn-text text-white text-uppercase pl-4">
                            Zjistit více{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                        </div>
                        {/* </Link> */}
                    </div> 
                    </div>
                </div>
                </section>
            </div>
        </header>
    );
}

renderMainContainer = () => {
    const { bodyPost, whoWeAreImg, whoWeAreIndex, whoWeAre } = this.state;
    return(
        <section id="main-container">
            <div className="sd-container">
                <section id="pictured-navigation">
                <div className="row justify-content-center">
                    <Link
                        to={"/projekce-a-vyvoj"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="o-padding pic-nav first-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                                Projekce a vývoj
                            </span>
                        </div>
                    </Link>
                    {/* <Link
                        to={"/strojirenstvi"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="no-padding pic-nav second-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                                Strojírentsví
                            </span>
                        </div>
                    </Link> */}
                    <Link
                        to={"/"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="no-padding pic-nav second-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                                Strojírenství
                            </span>
                        </div>
                    </Link>
                    <Link
                        to={"/technologie-pro-tezbu-a-energetiku"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="no-padding pic-nav third-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                            Technologie pro těžbu a energetiku
                            </span>
                        </div>
                    </Link>
                    <Link
                        to={"/fotovoltaika"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="no-padding pic-nav fourth-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                            Fotovoltaika
                            </span>
                        </div>
                    </Link>
                </div>
                </section>

                <section id="who-we-are">
                <div className="row">
                    <div className="col-md-6">
                    <div className="d-flex justify-content-center align-items-center who-we-are" style={{backgroundImage:`url("${whoWeAreImg}")`}}>
                        <div className="who-we-are-paging">
                        <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link" onClick={() => this.whoWeAreToggle("back")}>
                            <i className="fa fa-chevron-left"></i>
                        </div>
                        <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center">
                            {whoWeAreIndex}/{whoWeAre.length}
                        </div>
                        <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link" onClick={() => this.whoWeAreToggle("next")}>
                            <i className="fa fa-chevron-right"></i>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 p-0-50">
                    <div className="d-flex justify-content-between align-items-center mt-4-v2">
                        <h6 className="who-we-are-sub-caption text-uppercase">
                        Zjistěte
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="who-we-are-caption text-uppercase">
                        {bodyPost.title}
                        </h2>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="who-we-are-text">
                        {renderHTML(bodyPost.text)}
                        </div>
                    </div>
                    <div className="row who-we-are-gallery mb-4">
                        <div className="col-4 p-1">
                        <a href={home1}><img src={home1} alt="thumbnail-galerie-clanek" /></a>
                        </div>
                        <div className="col-4 p-1">
                        <a href={home2}><img src={home2} alt="thumbnail-galerie-clanek" /></a>
                        </div>
                        <div className="col-4 p-1">
                        <a href={home3}><img src={home3} alt="thumbnail-galerie-clanek" /></a>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <Link
                        to={"/o-spolecnosti"}
                        className="who-we-are-more-btn-link"
                        >
                        <div className="who-we-are-more-btn text-white d-flex align-items-center">
                            <span className="btn-text text-uppercase">
                            Více o nás{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                        </div>
                        </Link>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </section>
    );
}

renderSecondaryContainer = () => {
    return(
        <section id="secondary-container"></section>
    );
}

renderActualMobileDevice = () => {
    const { actualityPost, actualityPosts, index } = this.state;

    return(
        <section id="actual-xs">
            <div className="row m-0">
                <div className="col actual p-50">
                <div className="d-flex justify-content-between align-items-center mt-4-v2">
                    <h6 className="actual-into-deep-caption text-uppercase">
                    <a href="/aktuality" className="text-white link">Aktuality</a>
                    </h6>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                    <h2 className="actual-into-deep-subcaption text-uppercase">
                    {actualityPost.title}
                    </h2>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="actual-into-deep-text">
                    {renderHTML(actualityPost.shortText)}
                    </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                    <Link
                    to={`clanek/${actualityPost.slug}`}
                    className="who-we-are-more-btn-link"
                    >
                    <div className="actual-more-btn link text-white">
                        <span className="btn-text text-uppercase">
                        Zjistit více <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                    </div>
                    </Link>
                </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col p-0">
                <div className="actual-paging">
                    <div
                    className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link"
                    onClick={() => this.actualityToggle("back")}
                    >
                    <i className="fa fa-chevron-left"></i>
                    </div>
                    <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center">
                    {index}/{actualityPosts.length}
                    </div>
                    <div
                    className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link"
                    onClick={() => this.actualityToggle("next")}
                    >
                    <i className="fa fa-chevron-right"></i>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

renderFooter = () => {
    const { actualityPost, actualityPosts, index, indexRef, footerPost, footerPosts } = this.state;

    return(
        <footer>
            <div className="sd-container">
                <section id="actual-into-deep">
                    <div className="row m-0">
                        <div className="col-md-6 p-50 actual sd-hidden-xs">
                        <div className="d-flex justify-content-between align-items-center mt-4-v2">
                            <h6 className="actual-into-deep-caption text-uppercase">
                            <a href="/aktuality" className="text-white link">Aktuality</a>
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                            <h2 className="actual-into-deep-subcaption text-uppercase">
                            {actualityPost.title}
                            </h2>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="actual-into-deep-text">
                            {renderHTML(actualityPost.shortText)}
                            </div>
                        </div>
                        <div className="actual-paging">
                            <div
                            className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link"
                            onClick={() => this.actualityToggle("back")}
                            >
                            <i className="fa fa-chevron-left"></i>
                            </div>
                            <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center">
                            {index}/{actualityPosts.length}
                            </div>
                            <div
                            className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link"
                            onClick={() => this.actualityToggle("next")}
                            >
                            <i className="fa fa-chevron-right"></i>
                            </div>
                        </div>
                        <Link
                            to={`clanek/${actualityPost.slug}`}
                            className="who-we-are-more-btn-link"
                        >
                            <div className="actual-more-btn link text-white">
                            <span className="btn-text text-uppercase">
                                Zjistit více{" "}
                                <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                            </div>
                        </Link>
                        </div>
                        <div className="col-md-6 p-50 into-deep">
                        <div className="d-flex justify-content-between align-items-center mt-4-v2">
                            <h6 className="actual-into-deep-caption text-uppercase">
                            <a href="/reference" className="text-white link">Reference</a>
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                            <h2 className="actual-into-deep-subcaption text-uppercase">
                            {footerPost.title}
                            </h2>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="actual-into-deep-text">
                            {renderHTML(footerPost.shortText)}
                            </div>
                        </div>
                        <div className="actual-paging">
                            <div
                            className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link"
                            onClick={() => this.referenceToggle("back")}
                            >
                            <i className="fa fa-chevron-left"></i>
                            </div>
                            <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center">
                            {indexRef}/{footerPosts.length}
                            </div>
                            <div
                            className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link"
                            onClick={() => this.referenceToggle("next")}
                            >
                            <i className="fa fa-chevron-right"></i>
                            </div>
                        </div>
                        {(footerPost.slug) == "fotovoltaika" ?
                        
                        <Link
                             to={`${footerPost.slug}`}
                            className="who-we-are-more-btn-link"
                        >
                            <div className="actual-more-btn link text-white">
                            <span className="btn-text text-uppercase">
                                Zjistit více{" "}
                                <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                            </div>
                        </Link>

: 
                        <Link
                             to={`clanek/${footerPost.slug}`}
                            className="who-we-are-more-btn-link"
                        >
                            <div className="actual-more-btn link text-white">
                            <span className="btn-text text-uppercase">
                                Zjistit více{" "}
                                <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                            </div>
                        </Link>
} 
                        </div>
                    </div>
                </section>

                <section id="footer-content" className="footer-top-padding">
                    <Footer />
                </section>
            </div>
        </footer>            
    );
}

render() {
    const { isLoading } = this.state; 
    //const isLoading  = true; 

    return (  
        <section id="web">
            <div id="preload">
                {/* <img src={require('../../images/header-bg.jpg')}></img> */}
            </div>

            {isLoading ? (
                <Preloader isLoaded={!isLoading} />
            ) : ( 
                <div className="wrapper">
                    {this.renderHeader()}

                    {this.renderMainContainer()}

                    {this.renderSecondaryContainer()}

                    {this.renderActualMobileDevice()}

                    {this.renderFooter()}
                </div>
            )}
        </section>
    );
  }
}

export default Homepage;