import React from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import {
    WORDPRESS_URL,
    WP_POSTS_SLUG
} from "../../utils/constants";
import renderHTML from 'react-render-html';
import Preloader from "../Preloader";
import { Redirect } from 'react-router-dom';
import kosodelnik from "../../images/kosodelnik.png";
import kosodelnikModry from "../../images/kosodelnik-modry.png";
import api from '../../Api';
import functions from '../../utils/functions';

class Contact extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            cta1: 'cta1-active',
            cta2: 'cta2',
            cta3: 'cta3',
            cta4: 'cta4',
            cta5: 'cta5',
            cta6: 'cta6',
            cta7: 'cta7',
            ctaActive: 'cta1',
            selectedContactCategory: 'GENERAL',
            bodyPost: {},
            isLoading: false,
            text: '',
            textError: "",
            firstname: '',
            firstnameError: "",
            lastname: '',
            lastnameError: "",
            phone: '',
            phoneError: "",
            captcha: '',
            captchaError: "",
            index: 1,
            headerBgClass: "home",
            firstRectSrc: kosodelnikModry,
            secondRectSrc: kosodelnik, 
            thirdRectSrc: kosodelnik, 
            nextHeaderBgIndex: 2,
            firstArticle: "",
            secondArticle: "hidden",
            thirdArticle: "hidden",
            leadership: "hidden",
            spokesperson: "hidden",
            vacancies: "hidden",
            accounting: "hidden",
            complaints: "hidden",
            questions: "hidden",
            reclamations: "hidden",
            support: "hidden",
            sale: "hidden",
            error: {},
            redirectInternalError: false,
            emailSendAlertClass: "alert-success",
            emailSendAlertText: "Váš dotaz byl úspěšně odeslán, děkujeme!",
            emailSendAlertDisplay: "d-none",
            emailSendButtonText: "Odeslat zprávu",
            emailSendButtonFa: "fa-chevron-right"
        };

        this.ctaToggle = this.ctaToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.bgToggle = this.bgToggle.bind(this);
        this.ContactToggle = this.ContactToggle.bind(this);
    }

    bgToggle(number) {
        if (number === 1) {
          this.setState({
              firstRectSrc: kosodelnikModry,
              secondRectSrc: kosodelnik,
              thirdRectSrc: kosodelnik,
              headerBgClass: "projects",
              nextHeaderBgIndex: 2,
              firstArticle: "",
              secondArticle: "hidden",
              thirdArticle: "hidden"
          });
        } 
        else if (number === 2) {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnikModry,
                thirdRectSrc: kosodelnik,
                headerBgClass: "construction",
                nextHeaderBgIndex: 3,
                firstArticle: "hidden",
                secondArticle: "",
                thirdArticle: "hidden"
            });
          } else {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnik,
                thirdRectSrc: kosodelnikModry,
                headerBgClass: "delivery",
                nextHeaderBgIndex: 1,
                firstArticle: "hidden",
                secondArticle: "hidden",
                thirdArticle: ""
            });
        }
      }

      ContactToggle(section){
        this.setState({
            [section]: this.state[section] === "hidden" ? "" : "hidden"
        });
      }

    componentDidMount(){
        this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }

    ctaToggle = (ctaName) => {
        let cta1, cta2, cta3, cta4, cta5, cta6, cta7= '';
        let selectedContactCategory = this.state.selectedContactCategory;

        if(ctaName==='cta1'){
            cta1='cta1-active';
            selectedContactCategory = 'GENERAL';
        }
        else{
            cta1='cta1';
        }

        if(ctaName==='cta2'){
            cta2='cta2-active';            
            selectedContactCategory = "WORK";
        }
        else{
            cta2='cta2';
        }

        if(ctaName==='cta3'){
            cta3='cta3-active';
            selectedContactCategory = "BUSINESS";
        }
        else{
            cta3='cta3'
        }

        if(ctaName==='cta4'){
            cta4='cta4-active'
            selectedContactCategory = "MEDIA";
        }
        else{
            cta4='cta4'
        }

        if(ctaName==='cta5'){
            cta5='cta5-active'
            selectedContactCategory = "HQ";
        }
        else{
            cta5='cta5'
        }

        if(ctaName==='cta6'){
            cta6='cta6-active'
            selectedContactCategory = "COAL";
        }
        else{
            cta6='cta6'
        }

        if(ctaName==='cta7'){
            cta7='cta7-active'
            selectedContactCategory = "INVOICE";
        }
        else{
            cta7='cta7'
        }

        this.setState({
            cta1:cta1, 
            cta2:cta2, 
            cta3:cta3, 
            cta4:cta4, 
            cta5:cta5,
            cta6:cta6,
            cta7:cta7,
            ctaActive:ctaName, 
            selectedContactCategory: selectedContactCategory
        });
    }

    handleChange(event) {
        this.validateField(event.target.name, event.target.value);

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        const {firstname, lastname, phone, text, selectedContactCategory, captcha} = this.state;
        const recipients = functions.CategoryResponsibleEmails(selectedContactCategory);
        const companyWeb = "Severočeské doly a.s.";
        
        setTimeout(() => this.setState({ 
                emailSendAlertDisplay: 'd-none',  
                emailSendButtonText: "Odeslat zprávu",
                emailSendButtonFa: "fa-chevron-right"
            }), 10000
        );

        this.validateForm();

        if(!firstname || !lastname || !text || !phone || Number.isNaN(Number.parseInt(phone, 10)) || !captcha || captcha.toLowerCase() !== 'slunce') {
            event.preventDefault();
            return;
        }

        this.setState({
            emailSendButtonText: "Odesílám zprávu",
            emailSendButtonFa: "fa-spinner fa-spin"
        });

        let emailObject = {
            Recipients: recipients,
            Subject: "Zpráva z kontaktního formuláře na webu " + companyWeb,
            CompanyWeb: companyWeb,
            FirstName: firstname,
            LastName: lastname,
            Phone: Number.parseInt(phone, 10).toString(),
            EmailText: text
        }

        api.contact.send(emailObject).then((response)=> {
            if (response.data) {
                console.log(response.data);
                this.setState({
                    emailSendButtonText: "Zpráva odeslána",
                    emailSendButtonFa: "fa-check",
                    emailSendAlertDisplay: "d-block",
                    firstname: "",
                    lastname: "",
                    phone: "",
                    text: "",
                    captcha: ""    
                });
            } else {
                this.setState({
                    emailSendButtonText: "Zpráva neodeslána",
                    emailSendButtonFa: "fa-times",
                    emailSendAlertDisplay: "d-block",                    
                    emailSendAlertClass: "alert-danger",
                    emailSendAlertText: "Při odeslání formuláře došlo k chybě, zkuste to prosím později",
                });
            }            
        }).catch(() => {
            this.setState({
                emailSendButtonText: "Zpráva neodeslána",
                emailSendButtonFa: "fa-times",
                emailSendAlertDisplay: "d-block",                    
                emailSendAlertClass: "alert-danger",
                emailSendAlertText: "Při odeslání formuláře došlo k chybě, zkuste to prosím později",
            });
        });
        
    }

    validateForm = () => {
        const { firstname, lastname, phone, text, captcha } = this.state;

        this.validateField("firstname", firstname);
        this.validateField("lastname", lastname);
        this.validateField("phone", phone);
        this.validateField("text", text);
        this.validateField("captcha", captcha);
    }

    validateField = (fieldName, value) => {
        switch(fieldName) {
            case 'firstname':      
                if(!value) {
                    this.setState({firstnameError: "contact-form-shadow-error"});
                } else {
                    this.setState({firstnameError: ""});
                }     

                break;
            case 'lastname':            
                if(!value) {
                    this.setState({lastnameError: "contact-form-shadow-error"});
                } else {
                    this.setState({lastnameError: ""});
                }    

                break;
            case 'phone':                        
                if(!value || Number.isNaN(Number.parseInt(value, 10))) {
                    this.setState({phoneError: "contact-form-shadow-error"});
                } else {
                    this.setState({phoneError: ""});
                }    

                break;
            case 'text':                  
                if(!value) {
                    this.setState({textError: "contact-form-shadow-error"});
                } else {
                    this.setState({textError: ""});
                }  

                break;
            case 'captcha':                  
                if(!value || value.toLowerCase() !== 'slunce') {
                    this.setState({captchaError: "contact-form-shadow-error"});
                } else {
                    this.setState({captchaError: ""});
                }  

                break;
          default:
            break;
        }
      }

    renderHeader = () => {
        return(
            <header className={"contact-header " + this.state.headerBgClass}>
                <Navbar/>
    
                <div className="sd-container">
                    <section id="sub-header">
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center sub-header-sub-caption-visibility">
                                    <h6 className="sub-header-sub-caption text-uppercase prodeco-h6">Zde najdete nejdůležitější</h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="sub-header-caption text-uppercase">Kontakty</h6>
                                </div> 
                                <div className="d-flex justify-content-between align-items-center">
                        <span className="sub-header-chevrons">
                        <img
                            src={this.state.firstRectSrc}
                            alt="chevron_white"
                            className="first header-toogle-button"
                            onClick={() => this.bgToggle(1)}
                        />
                        <img
                            src={this.state.secondRectSrc}
                            alt="chevron_sd"
                            className="second header-toogle-button"
                            onClick={() => this.bgToggle(2)}
                        />
                        
                        <img
                            src={this.state.thirdRectSrc}
                            alt="chevron_sd"
                            className="third header-toogle-button"
                            onClick={() => this.bgToggle(3)}
                        />
                        </span>
                    </div>                    
                            </div>                        
                        </div>
    
                        <div className="row">
                            <div className="col-md-3">
                                <h5 className="text-white text-uppercase mb-3">Adresa</h5>
                                <p>
                                    PRODECO, a.s <br />
                                    Důlní 437 <br />
                                    Mostecké Předměstí <br/>
                                    418 01, Bílina 
                                </p>
                            </div>
                            <div className="col-md-3">
                                <h5 className="text-white text-uppercase mb-3">Fakturační údaje</h5>
                                <p>
                                    IČ: 25020790  <br />
                                    DIČ: CZ699005746
                                </p>
                            </div>
                            <div className="col-md-3">
                                <h5 className="text-white text-uppercase mb-3">Datová schránka</h5>
                                <p>
                                tkfftpb
                                </p>
                            </div>
                            <div className="col-md-3">
                                <h5 className="text-white text-uppercase mb-3">&nbsp;</h5>
                                <p>Společnost je zapsána v obchodním rejstříku vedeném Krajským soudem v Ústí nad Labem odd. B, vložka 989.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </header> 
        );
    }
    
    renderMainContainer = () => {

        return(
            <section id="main-container" className="mb-5">
                <div className="sd-container">
                <section id="about" className="p-3">
                        <h1 className="text-uppercase">Kontaktní údaje</h1>
                    </section>
                    
                    <section id="detail-contact" className="pb-3 pt-3 pl-3 pl-md-5 pr-3 pr-md-5">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">E-Mail</h4>
                            </div>
                                <div className="col-12 pl-0 pr-0 d-flex justify-content-between pb-4 ">
                                <div className="col-sm-12 col-md-6 sd-hidden-xs">
                                </div>
                                <div className="col-sm-12 col-md-6 detail-contact-text-position">prodeco@prodeco.cz</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Provoz Bílina</h4>
                            </div>
                            <div className="col-12 pl-0 pr-0 justify-content-between pb-4 ">
                            <div className="col-12 pl-0 pr-0 d-flex justify-content-between ">
                                <div className="col-sm-12 col-md-6 sd-hidden-xs">
                                </div>
                                <div className="col-sm-12 col-md-6 detail-contact-text-position"> +420 417 804 911</div>
                                </div>
                                <div className="col-12 pl-0 pr-0 d-flex justify-content-between">
                                <div className="col-sm-12 col-md-6 sd-hidden-xs">
                                </div>
                                {/* <div className="col-sm-12 col-md-6 detail-contact-text-position"><i className="fas fa-fax"></i> +420 417 804 912</div> */}
                                </div>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Provoz Tušimice</h4>
                            </div>
                            <div className='col-12 d-flex pl-0 pr-0 pb-4'>
                            <div className="col-sm-12 col-md-6 pl-0 pr-0 ">
                            <div className="col-12 pl-0 pr-0">
                                <div className="col-12"> Tušimice 24</div>
                                </div>
                                <div className="col-12 pl-0 pr-0">
                                <div className="col-12">432 01, Kadaň</div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 pl-0 pr-0">
                            <div className="col-12 pl-0 pr-0 ">
                                <div className="col-12 detail-contact-text-position"> +420 474 604 370</div>
                                </div>
                                <div className="col-12 pl-0 pr-0">
                                {/* <div className="col-12 detail-contact-text-position"><i className="fas fa-fax"></i> +420 474 604 366</div> */}
                                </div>
                            </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Odbor Hydraulika</h4>
                            </div>
                            <div className='col-12 d-flex pl-0 pr-0 pb-4'>
                            <div className="col-sm-12 col-md-6 pl-0 pr-0 ">
                            <div className="col-12 pl-0 pr-0">
                                <div className="col-12"> Mládežnická 343</div>
                                </div>
                                <div className="col-12 pl-0 pr-0">
                                <div className="col-12">418 01, Bílina </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 pl-0 pr-0">
                            <div className="col-12 pl-0 pr-0 ">
                                <div className="col-12 detail-contact-text-position"> +420 417 804 948</div>
                                </div>
                                <div className="col-12 pl-0 pr-0">
                            </div>
                            </div>
                        </div>
                        </div>

                        
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Odbor Elektro ASŘ</h4>
                            </div>
                            <div className='col-12 d-flex pl-0 pr-0 pb-4'>
                            <div className="col-sm-12 col-md-6 pl-0 pr-0 ">
                            <div className="col-12 pl-0 pr-0">
                                <div className="col-12"> Areál Doly Nástup Tušimice</div>
                                </div>
                                <div className="col-12 pl-0 pr-0">
                                <div className="col-12">431 45, Březno u Chomutova</div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 pl-0 pr-0">
                            <div className="col-12 pl-0 pr-0 ">
                                <div className="col-12 detail-contact-text-position"> +420 724 087 214 </div>
                                </div>
                            </div> 
                            </div>
                        </div>

                          
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Mzdová účtárna</h4>
                            </div>
                            <div className='col-12 d-flex pl-0 pr-0 pb-4'>
                            <div className="col-sm-12 col-md-6 pl-0 pr-0 ">
                            <div className="col-12 pl-0 pr-0">
                                <div className="col-12"></div>
                                </div>
                                <div className="col-12 pl-0 pr-0">
                                <div className="col-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 pl-0 pr-0">
                            <div className="col-12 pl-0 pr-0 ">
                                <div className="col-12 detail-contact-text-position"> +420 417 804 909</div>
                                </div>
                                <div className="col-12 pl-0 pr-0">
                                <div className="col-12 detail-contact-text-position"> +420 417 804 908</div>
                                </div>
                            </div>
                            </div>
                        </div>

                    </section>
                </div>
            </section>
        );
    }
    
    renderSecondaryContainer = () => {
        return(
            <section id="secondary-container" className="sd-hidden-xs contact-secondary-container"></section>
        );
    }
    
    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>           
        );
    }
    
    render(){
        const { isLoading, redirectInternalError, error } = this.state;
    
        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                ) : (redirectInternalError ? (
                    <Redirect to={{
                        pathname: "/500",
                        state: { error: error }
                      }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}
    
                        {this.renderMainContainer()}
    
                        {this.renderSecondaryContainer()}
    
                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default Contact;